@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.App {
  text-align: center;
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  .footer {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #707070;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
  }

  .footer a {
    flex-basis: 30%;
    text-align: center;
    padding: 10px;
  }
  
  .footer img {
    height: 70px;
    object-fit: contain;
    
  }

  .smaller {
    height: 40px !important;
  }

  .navbar-brand {
    padding-left:10px;
  }

  .baseline {
    font-family:'Poppins';
    font-size: 50px;
    margin-left: 110px;
    font-weight: bold;
    color:#FF5757;
    
  }
  .baseline-img {
    justify-content: center;
    height: 280px;
    margin-left: 80px;
    margin-top: 0px;
    
  }
 .cod4 {
  margin-left: 965px;
 }
  .header {
    height: 80px;
  }
.bulle {
  width: 200px;
  margin-left:200px;
  margin-top : -100px;
  
}
.bulle2 {
  width: 296px;
  margin-left:190px;
  margin-top : -120px;
  
}
  .go {
    font-family:'Poppins';
    height: 90px;
    background-color: #00A651;
    border-radius:25px;
    width:200px;
    text-align: center;
    justify-content: center;
    margin-left:200px;
    margin-top:50px;
    color : #ededed;
    font-weight: bold;
    padding-top: 10px;
    font-size: 25px;
    text-decoration:none;
    /* box-shadow: 0px 0px 5px 1px rgba(60, 58, 58, 0.75); */
  }
  .go2{
    margin-top : 15px
  }
  .register {
    font-family:'Poppins';
    height: 105px;
    background-color: #00A651;
    border-radius:25px;
    width:280px;
    text-align: center;
    justify-content: center;
    margin-left:200px;
    margin-top:20px;
    color : #ededed;
    font-weight: bold;
    padding-top: 10px;
    font-size: 23px;
    text-decoration:none;
    box-shadow: 0px 0px 15px 4px rgba(130, 135, 142, 0.592);

  }
.line {
  background-color: rgb(20, 20, 20);
  height: 4px;
}

  .contbl {
    display:flex;
  }

  .indic1 {
    font-family:'Poppins';
    height: 55px;
    background-color: #0282C8;
    border-radius:25px;
    width:550px;
    text-align: center;
    justify-content: center;
    margin-left:50px;
    margin-top:20px;
    margin-bottom : 20px;
    color : #ededed;
    font-weight: bold;
    font-size: 25px;
    padding-top: 10px;
    box-shadow: 0px 0px 15px 4px rgba(130, 135, 142, 0.592);
  }

  .indic2 {
    font-family:'Poppins';
    height: 55px;
    background-color: #fcbf31;
    border-radius:25px;
    width:550px;
    text-align: center;
    justify-content: center;
    margin-left:50px;
    margin-right:0px;
    margin-top:20px;
    margin-bottom : 20px;
    color : #ffffff;
    font-weight: bold;
    font-size: 25px;
    padding-top: 10px;
    box-shadow: 0px 0px 15px 4px rgba(130, 135, 142, 0.592);
  }

  .indic3 {
    font-family:'Poppins';
    height: 55px;
    background-color: #ED354F;
    border-radius:25px;
    width:550px;
    text-align: center;
    justify-content: center;
    margin-left:50px;
    margin-top:20px;
    margin-bottom : 20px;
    color : #ededed;
    font-weight: bold;
    font-size: 25px;
    padding-top: 10px;
    box-shadow: 0px 0px 15px 4px rgba(130, 135, 142, 0.592);
  }
  .indic4 {
    font-family:'Poppins';
    height: 70px;
    background-color: #ED354F;
    border-radius:25px;
    width:230px;
    text-align: center;
    justify-content: center;
    margin-left:300px;
    margin-top:50px;
    margin-bottom : 50px;
    color : #ededed;
    font-weight: bold;
    padding-top: 10px;
    
  }

  a:link {
    text-decoration: none;
    color :rgb(20, 20, 20)
  }

 .flex1 {
  display: flex;
 }

  .blbody {
    background-color: #eaebf2;
  }

 

  a {
    color: #dadadb !important;
    text-decoration: none;
}

