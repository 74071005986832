.icon {
  border-radius: 50%;
  object-fit: cover;
  width: 50px;
  height: 50px;
  margin-top: 20px;
}

.cardPays{
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  margin: 10px;
}